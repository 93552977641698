import React, { Suspense } from "react";

import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../frontend/style/style.css";

import { Toaster } from "react-hot-toast";
import ScrollToTop from "../components/ScrollToTop";

import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/themes";

import Progress from "react-progress-2";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";
import Login from "../auth";
import PublicRouter from "../routers";

function App() {
      return (
            <>
                  <Progress.Component
                        style={{ background: "#99999978", height: "5px" }}
                        thumbStyle={{
                              background: "rgb(232, 107, 25)",
                              height: "5px",
                        }}
                  />

                  <ThemeProvider theme={lightTheme}>
                        <ScrollToTop />
                        <Toaster position="top-right" />
                        <Suspense fallback="Loading">
                              {useLocation().pathname === "/" ? (
                                    <Routes>
                                          <Route path="/" element={<Login />} />
                                    </Routes>
                              ) : (
                                    <PrivateLayout>
                                          <PublicRouter />
                                    </PrivateLayout>
                              )}
                        </Suspense>
                  </ThemeProvider>
            </>
      );
}

export default App;
