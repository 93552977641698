import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
      CardHeader,
      CardLinks,
      CardTitle,
} from "../../styles/components/common/Table";
import { LinkButton } from "../../styles/components/common/Button";
import Input from "../../components/form/FormInput";

import $ from "jquery";
import axios from "axios";

import { toast } from "react-hot-toast";
import FormSeo from "../../components/common/FormSeo";
import MultipleImage from "../../components/form/MultipleImage";
import FormDescription from "../../components/form/FormDescription";
import FormButton from "../../components/form/FormButton";
import FormStatusSelect from "../../components/form/FormStatusSelect";
import FormImage from "../../components/form/FormImage";

const ProjectCreate = () => {
      const [loading, setLoading] = useState("");
      const [location, setLocation] = useState("");
      const [website, setWebsite] = useState("");
      const [title, setTitle] = useState("");
      const [slug, setSlug] = useState("");
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [description, setDescription] = useState("");
      const [date, setDate] = useState("");
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [status, setStatus] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  slug: slug,
                  location: location,
                  website: website,
                  status: status,
                  date: date,
                  description: description,
                  image: image,
                  images: imageFiles,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/projects`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              $("form").trigger("reset");
                              setImageUrl("");
                              setImages([]);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.date) {
                                    toast.error(response.data.message.date);
                              }
                              if (response.data.message.location) {
                                    toast.error(response.data.message.location);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.website) {
                                    toast.error(response.data.message.website);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Project
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/projects">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Project Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Project Slug"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <Input
                                                            title="Location"
                                                            classes={4}
                                                            handleChange={
                                                                  setLocation
                                                            }
                                                      />
                                                      <Input
                                                            title="Website"
                                                            classes={4}
                                                            handleChange={
                                                                  setWebsite
                                                            }
                                                      />
                                                      <Input
                                                            title="Project Date"
                                                            classes={4}
                                                            type="date"
                                                            handleChange={
                                                                  setDate
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormDescription
                                                            required={true}
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <MultipleImage
                                                            title="Other Images (Upload multiple images)"
                                                            images={images}
                                                            grid={4}
                                                            classes={8}
                                                            setImages={
                                                                  setImages
                                                            }
                                                            setImageFiles={
                                                                  setImageFiles
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProjectCreate;
