import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";

import { toast } from "react-hot-toast";
import Progress from "react-progress-2";
import "react-progress-2/main.css";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import ProductListBox from "../../components/backend/ProductListBox";
import LinkButton from "../../components/common/LinkButton";

const ProductList = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState("");

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`,
                  },
            })
                  .then((response) => {
                        setProducts([]);
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Product List">
                                          <LinkButton
                                                title="Add New"
                                                link="/dashboard/products/create"
                                          />
                                          <LinkButton
                                                title="Manage Order"
                                                link="/dashboard/products/order"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          totalItems={totalProducts}
                                          getData={getData}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                    >
                                          <ProductListBox
                                                loading={loading}
                                                setLoading={setLoading}
                                                products={products}
                                                loadData={loadData}
                                                setProducts={setProducts}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductList;
