import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../backend/dashboard";

// Category
import {
      CategoryList,
      CategoryCreate,
      CategoryEdit,
      CategoryShow,
      CategoryManage,
      CategoryHomeManage,
} from "../backend/category";
import {
      ProductCreate,
      ProductEdit,
      ProductList,
      ProductOrder,
      ProductShow,
} from "../backend/products";
import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow,
} from "../backend/banner";
import {
      TestimonialList,
      TestimonialCreate,
      TestimonialShow,
      TestimonialEdit,
} from "../backend/testimonials";
import {
      CatalogueList,
      CatalogueCreate,
      CatalogueShow,
      CatalogueEdit,
} from "../backend/catalogue";
import { PageList, PageCreate, PageEdit, PageShow } from "../backend/pages";
import { RoleCreate, RoleEdit, RoleList } from "../backend/roles";
import {
      BlogCategoryList,
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryShow,
} from "../backend/blogCategory";
import { BlogCreate, BlogEdit, BlogShow, BlogTable } from "../backend/blog";
import {
      FaqTypeCreate,
      FaqTypeEdit,
      FaqTypeList,
      FaqTypeShow,
} from "../backend/faqType";
import { FaqCreate, FaqEdit, FaqList, FaqShow } from "../backend/faqs";
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow,
} from "../backend/faqRequest";
import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow,
} from "../backend/plugins";
import { JobCreate, JobEdit, JobList, JobShow } from "../backend/jobs";
import { CandidateList, CandidateShow } from "../backend/candidates";
import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList,
} from "../backend/subscribers";
import {
      ContactRequestList,
      ContactRequestShow,
} from "../backend/contactRequest";
import { UserCreate, UserEdit, UserList, UserShow } from "../backend/users";
import PageNotFound from "../components/PageNotFound";
import {
      BrandCreate,
      BrandEdit,
      BrandList,
      BrandShow,
} from "../backend/brands";
import {
      ProjectCreate,
      ProjectEdit,
      ProjectList,
      ProjectShow,
} from "../backend/projects";
import {
      ServiceCreate,
      ServiceEdit,
      ServiceList,
      ServiceShow,
} from "../backend/services";
import {
      AssociationCategoryCreate,
      AssociationCategoryEdit,
      AssociationCategoryList,
      AssociationCategoryShow,
} from "../backend/association_categories";
import {
      AssociationCreate,
      AssociationEdit,
      AssociationList,
      AssociationShow,
} from "../backend/associations";
import {
      ConcernCreate,
      ConcernEdit,
      ConcernList,
      ConcernShow,
} from "../backend/concerns";
import { ShopCreate, ShopEdit, ShopList, ShopShow } from "../backend/shops";
import {
      ProductEnquiryList,
      ProductEnquiryShow,
} from "../backend/product_enquiries";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Brand */}
                        <Route
                              path="/dashboard/brands"
                              element={<BrandList />}
                        />
                        <Route
                              path="/dashboard/brands/create"
                              element={<BrandCreate />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/edit"
                              element={<BrandEdit />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/show"
                              element={<BrandShow />}
                        />

                        {/* Category */}
                        <Route
                              path="/dashboard/categories"
                              element={<CategoryList />}
                        />
                        <Route
                              path="/dashboard/categories/create"
                              element={<CategoryCreate />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/edit"
                              element={<CategoryEdit />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/show"
                              element={<CategoryShow />}
                        />
                        <Route
                              path="/dashboard/categories/manage/order"
                              element={<CategoryManage />}
                        />
                        <Route
                              path="/dashboard/categories/home/order"
                              element={<CategoryHomeManage />}
                        />

                        {/* Product */}
                        <Route
                              path="/dashboard/products"
                              element={<ProductList />}
                        />
                        <Route
                              path="/dashboard/products/create"
                              element={<ProductCreate />}
                        />
                        <Route
                              path="/dashboard/products/:productId/edit"
                              element={<ProductEdit />}
                        />
                        <Route
                              path="/dashboard/products/:productId/show"
                              element={<ProductShow />}
                        />
                        <Route
                              path="/dashboard/products/order"
                              element={<ProductOrder />}
                        />

                        {/* Product Enquiry */}
                        <Route
                              path="/dashboard/product_enquiries"
                              element={<ProductEnquiryList />}
                        />
                        <Route
                              path="/dashboard/product_enquiries/:enquiryId/show"
                              element={<ProductEnquiryShow />}
                        />

                        {/* Project */}
                        <Route
                              path="/dashboard/projects"
                              element={<ProjectList />}
                        />
                        <Route
                              path="/dashboard/projects/create"
                              element={<ProjectCreate />}
                        />
                        <Route
                              path="/dashboard/projects/:projectId/edit"
                              element={<ProjectEdit />}
                        />
                        <Route
                              path="/dashboard/projects/:projectId/show"
                              element={<ProjectShow />}
                        />

                        {/* Service */}
                        <Route
                              path="/dashboard/services"
                              element={<ServiceList />}
                        />
                        <Route
                              path="/dashboard/services/create"
                              element={<ServiceCreate />}
                        />
                        <Route
                              path="/dashboard/services/:serviceId/edit"
                              element={<ServiceEdit />}
                        />
                        <Route
                              path="/dashboard/services/:serviceId/show"
                              element={<ServiceShow />}
                        />

                        {/* Shop */}
                        <Route path="/dashboard/shops" element={<ShopList />} />
                        <Route
                              path="/dashboard/shops/create"
                              element={<ShopCreate />}
                        />
                        <Route
                              path="/dashboard/shops/:shopId/edit"
                              element={<ShopEdit />}
                        />
                        <Route
                              path="/dashboard/shops/:shopId/show"
                              element={<ShopShow />}
                        />

                        {/* Faq */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/show"
                              element={<FaqTypeShow />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faqs/requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Job */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        {/* Complain */}
                        <Route
                              path="/dashboard/associations"
                              element={<AssociationList />}
                        />
                        <Route
                              path="/dashboard/associations/create"
                              element={<AssociationCreate />}
                        />
                        <Route
                              path="/dashboard/associations/:associationId/show"
                              element={<AssociationShow />}
                        />
                        <Route
                              path="/dashboard/associations/:associationId/edit"
                              element={<AssociationEdit />}
                        />

                        {/* Complain Type */}
                        <Route
                              path="/dashboard/association_categories"
                              element={<AssociationCategoryList />}
                        />
                        <Route
                              path="/dashboard/association_categories/create"
                              element={<AssociationCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/association_categories/:categoryId/edit"
                              element={<AssociationCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/association_categories/:categoryId/show"
                              element={<AssociationCategoryShow />}
                        />

                        {/* Concern */}
                        <Route
                              path="/dashboard/concerns"
                              element={<ConcernList />}
                        />
                        <Route
                              path="/dashboard/concerns/create"
                              element={<ConcernCreate />}
                        />
                        <Route
                              path="/dashboard/concerns/:concernId/edit"
                              element={<ConcernEdit />}
                        />
                        <Route
                              path="/dashboard/concerns/:concernId/show"
                              element={<ConcernShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact/requests"
                              element={<ContactRequestList />}
                        />
                        <Route
                              path="/dashboard/contact/requests/:contactId/show"
                              element={<ContactRequestShow />}
                        />

                        {/* Catalogue */}
                        <Route
                              path="/dashboard/catalogues"
                              element={<CatalogueList />}
                        />
                        <Route
                              path="/dashboard/catalogues/create"
                              element={<CatalogueCreate />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/edit"
                              element={<CatalogueEdit />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/show"
                              element={<CatalogueShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Blog */}
                        <Route
                              path="/dashboard/blogs"
                              element={<BlogTable />}
                        />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories/list"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/show"
                              element={<BlogCategoryShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
