import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
      CardHeader,
      CardLinks,
      CardTitle,
} from "../../styles/components/common/Table";
import { LinkButton } from "../../styles/components/common/Button";
import Input from "../../components/form/FormInput";

import $ from "jquery";
import axios from "axios";

import { toast } from "react-hot-toast";
import FormSelect from "../../components/form/FormSelect";
import FormSeo from "../../components/common/FormSeo";
import MultipleImage from "../../components/form/MultipleImage";
import FormDescription from "../../components/form/FormDescription";
import FormButton from "../../components/form/FormButton";
import FormStatusSelect from "../../components/form/FormStatusSelect";
import FormImage from "../../components/form/FormImage";
import ExtraDetail from "../../components/common/ExtraDetail";
import ExtraDownloads from "../../components/common/ExtraDownloads";
import FormInput from "../../components/form/FormInput";
import ShopDetail from "../../components/common/ShopDetail";

const options = [
      {
            if: "Promo",
            title: "Promo",
      },
      {
            id: "Hot",
            title: "Hot",
      },
];

const ProductCreate = () => {
      const [brands, setBrands] = useState([]);

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [loading, setLoading] = useState("");
      const [category, setcategory] = useState("");
      const [brand, setBrand] = useState("");
      const [title, setTitle] = useState("");
      const [slug, setSlug] = useState("");
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [description, setDescription] = useState("");
      const [summary, setSummary] = useState("");
      const [installation, setInstallation] = useState("");
      const [warrenty, setWarrenty] = useState("");
      const [specification, setSpecification] = useState("");
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [downloads, setDownloads] = useState([]);
      const [downloadFiles, setDownloadFiles] = useState([]);
      const [status, setStatus] = useState("");
      const [price, setPrice] = useState("");
      const [salePrice, setSalePrice] = useState("");
      const [type, setType] = useState("");
      const [stock, setStock] = useState("");

      const [darazLink, setDarazLink] = useState("");
      const [meroPasalLink, setMeroPasalLink] = useState("");
      const [hardwarePasalLink, setHardwarePasalLink] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                              setBrands(response.data.brands);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const loadCategory = async (value) => {
            setcategory(value);
            const data = {
                  id: value,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSubCategories(response.data.categories);
                        }
                  });
      };

      const loadSubCategory = async (value) => {
            setcategory(value);
            const data = {
                  id: value,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setChildCategories(response.data.categories);
                        }
                  });
      };

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  slug: slug,
                  brand: brand,
                  category: category,
                  status: status,
                  summary: summary,
                  description: description,
                  specification: specification,
                  installation: installation,
                  warrenty: warrenty,
                  image: image,
                  images: imageFiles,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
                  downloads: downloadFiles,
                  price: price,
                  stock: stock,
                  sale_price: salePrice,
                  type: type,
                  mero_pasal_link: meroPasalLink,
                  daraz_link: darazLink,
                  hardware_pasal_link: hardwarePasalLink
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              $("form").trigger("reset");
                              setImageUrl("");
                              setImages([]);
                              setImageFiles([]);
                              setDownloadFiles([]);
                              setDownloads([]);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.brand) {
                                    toast.error(response.data.message.brand);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.installation) {
                                    toast.error(
                                          response.data.message.installation
                                    );
                              }
                              if (response.data.message.warrenty) {
                                    toast.error(response.data.message.warrenty);
                              }
                              if (response.data.message.specification) {
                                    toast.error(
                                          response.data.message.specification
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.stock) {
                                    toast.error(response.data.message.stock);
                              }
                              if (response.data.message.price) {
                                    toast.error(response.data.message.price);
                              }
                              if (response.data.message.sale_price) {
                                    toast.error(
                                          response.data.message.sale_price
                                    );
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.hardware_pasal_link) {
                                    toast.error(
                                          response.data.message
                                                .hardware_pasal_link
                                    );
                              }
                              if (response.data.message.mero_pasal_link) {
                                    toast.error(
                                          response.data.message.mero_pasal_link
                                    );
                              }
                              if (response.data.message.daraz_link) {
                                    toast.error(
                                          response.data.message.daraz_link
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Create new Product
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/products">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                <Row className="g-3">
                                                      <Input
                                                            title="Product Name"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <Input
                                                            title="Product Slug"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Brand"
                                                            classes={4}
                                                            required={true}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={brands}
                                                            setData={setBrand}
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  loadCategory
                                                            }
                                                            required={true}
                                                            options={categories}
                                                      />
                                                      {subCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Sub Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        subCategories
                                                                  }
                                                            />
                                                      )}

                                                      {childCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Child Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        setcategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        childCategories
                                                                  }
                                                            />
                                                      )}
                                                      <FormInput
                                                            title="Price"
                                                            classes={4}
                                                            value={price}
                                                            handleChange={
                                                                  setPrice
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Sale Price"
                                                            classes={4}
                                                            value={salePrice}
                                                            handleChange={
                                                                  setSalePrice
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Stock"
                                                            classes={4}
                                                            value={stock}
                                                            handleChange={
                                                                  setStock
                                                            }
                                                      />
                                                      <FormSelect
                                                            options={options}
                                                            setData={setType}
                                                            title="Type"
                                                            classes={4}
                                                            selected={type}
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormDescription
                                                            required={true}
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <MultipleImage
                                                            title="Other Images (Upload multiple images)"
                                                            images={images}
                                                            grid={4}
                                                            classes={8}
                                                            setImages={
                                                                  setImages
                                                            }
                                                            setImageFiles={
                                                                  setImageFiles
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <ExtraDetail
                                          installation={installation}
                                          specification={specification}
                                          warrenty={warrenty}
                                          setInstallation={setInstallation}
                                          setWarrenty={setWarrenty}
                                          setSpecification={setSpecification}
                                    />

                                    <ExtraDownloads
                                          downlaods={downloads}
                                          setDownloadFiles={setDownloadFiles}
                                          setDownlods={setDownloads}
                                    />
                                    <ShopDetail
                                          darazLink={darazLink}
                                          setDarazLink={setDarazLink}
                                          hardwarePasalLink={hardwarePasalLink}
                                          setHardwarePasalLink={
                                                setHardwarePasalLink
                                          }
                                          meroPasalLink={meroPasalLink}
                                          setMeroPasalLink={setMeroPasalLink}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductCreate;
