import React from "react";
import CheckMultiplePermission from "../../roles/CheckMultiplePermission";
import { MenuSeparator } from "../../navigation/styles";
import MenuItem from "../../metis/MenuItem";
import MenuLink from "../../metis/MenuLink";
import { ProductIcon } from "../../dashboard";

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              "product-list",
                              "product-create",
                              "product-edit",
                              "product-show",
                              "category-list",
                              "category-create",
                              "category-edit",
                              "category-show",
                              "brand-list",
                              "brand-edit",
                              "brand-create",
                              "brand-delete",
                              "project-list",
                              "project-create",
                              "project-edit",
                              "project-delete",
                              "association-list",
                              "association-edit",
                              "association-create",
                              "association-delete",
                              "association-category-list",
                              "association-category-edit",
                              "association-category-create",
                              "association-category-delete",
                              "product-enquiry-list",
                              "product-enquiry-delete",
                        ]}
                  >
                        <MenuSeparator>Ecommerce Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "product-list",
                                    "product-create",
                                    "product-edit",
                                    "product-show",
                                    "category-list",
                                    "category-create",
                                    "category-edit",
                                    "category-show",
                                    "brand-list",
                                    "brand-edit",
                                    "brand-create",
                                    "brand-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Products"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/products",
                                          "/dashboard/products/create",
                                          "/dashboard/products/:productId/edit",
                                          "/dashboard/products/:productId/show",
                                          "/dashboard/categories",
                                          "/dashboard/categories/create",
                                          "/dashboard/categories/:categoryId/edit",
                                          "/dashboard/categories/:categoryId/show",
                                          "/dashboard/brands",
                                          "/dashboard/brands/create",
                                          "/dashboard/brands/:brandId/edit",
                                          "/dashboard/brands/:brandId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "brand-list",
                                                "brand-create",
                                                "brand-edit",
                                                "brand-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Brand"
                                                link="/dashboard/brands"
                                                links={[
                                                      "/dashboard/brands",
                                                      "/dashboard/brands/create",
                                                      "/dashboard/brands/:brandId/edit",
                                                      "/dashboard/brands/:brandId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "category-list",
                                                "category-create",
                                                "category-edit",
                                                "category-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      "/dashboard/categories",
                                                      "/dashboard/categories/create",
                                                      "/dashboard/categories/:categoryId/edit",
                                                      "/dashboard/categories/:categoryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "product-list",
                                                "product-create",
                                                "product-edit",
                                                "product-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      "/dashboard/products",
                                                      "/dashboard/products/create",
                                                      "/dashboard/products/:productId/edit",
                                                      "/dashboard/products/:productId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "product-enquiry-list",
                                                "product-enquiry-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product Enquiry"
                                                link="/dashboard/product_enquiries"
                                                links={[
                                                      "/dashboard/product_enquiries",
                                                      "/dashboard/product_enquiries/:productId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "concern-list",
                                    "concern-create",
                                    "concern-edit",
                                    "concern-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Conern Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/concerns",
                                          "/dashboard/concerns/create",
                                          "/dashboard/concerns/:concernId/edit",
                                          "/dashboard/concerns/:concernId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "concern-list",
                                                "concern-create",
                                                "concern-edit",
                                                "concern-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Concern List"
                                                link="/dashboard/concerns"
                                                links={[
                                                      "/dashboard/concerns",
                                                      "/dashboard/concerns/create",
                                                      "/dashboard/concerns/:concernId/edit",
                                                      "/dashboard/concerns/:concernId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "association-list",
                                    "association-edit",
                                    "association-create",
                                    "association-delete",
                                    "association-category-list",
                                    "association-category-edit",
                                    "association-category-create",
                                    "association-category-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Association Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/associations",
                                          "/dashboard/associations/create",
                                          "/dashboard/associations/:associationId/edit",
                                          "/dashboard/associations/:associationId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "association-category-list",
                                                "association-category-create",
                                                "association-category-edit",
                                                "association-category-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/association_categories"
                                                links={[
                                                      "/dashboard/association_categories",
                                                      "/dashboard/association_categories/create",
                                                      "/dashboard/association_categories/:categoryId/edit",
                                                      "/dashboard/association_categories/:categoryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "association-list",
                                                "association-create",
                                                "association-edit",
                                                "association-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Association List"
                                                link="/dashboard/associations"
                                                links={[
                                                      "/dashboard/associations",
                                                      "/dashboard/associations/create",
                                                      "/dashboard/associations/:associationId/edit",
                                                      "/dashboard/associations/:associationId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "shop-list",
                                    "shop-edit",
                                    "shop-create",
                                    "shop-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Shop Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/shops",
                                          "/dashboard/shops/create",
                                          "/dashboard/shops/:shopId/edit",
                                          "/dashboard/shops/:shopId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "shop-list",
                                                "shop-create",
                                                "shop-edit",
                                                "shop-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Shop List"
                                                link="/dashboard/shops"
                                                links={[
                                                      "/dashboard/shops",
                                                      "/dashboard/shops/create",
                                                      "/dashboard/shops/:shopId/edit",
                                                      "/dashboard/shops/:shopId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
