export const convertAmount = (amount) => {
      return parseFloat(amount).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
      });
};

export const convertFloat = (amount) => {
      return parseFloat(amount).toFixed(2);
};
