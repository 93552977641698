import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const CategoryHomeManage = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [limit, setLimit] = useState(10000);
      const [orders, setOrders] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/home/orders/change?limit=${limit}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit]);

      const handleDragStart = (e, index) => {
            e.dataTransfer.setData("index", index.toString());
      };

      const handleDragOver = async (e, order) => {
            e.preventDefault();
            setOrders(order);
      };

      const handleDrop = async (e, dropIndex) => {
            const dragIndex = parseInt(e.dataTransfer.getData("index"));
            const draggedProduct = categories[dragIndex];

            const updatedProducts = categories.filter(
                  (item, index) => index !== dragIndex
            );

            updatedProducts.splice(dropIndex, 0, draggedProduct);

            setCategories(updatedProducts);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/home/orders/change`,
                        {
                              categories: updatedProducts,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <div>
                        {!loading ? (
                              categories?.map((item, index) => (
                                    <>
                                          <div
                                                className="draggable-row draggable-item"
                                                key={index}
                                                draggable="true"
                                                onDragStart={(e) =>
                                                      handleDragStart(e, index)
                                                }
                                                onDragOver={(e) =>
                                                      handleDragOver(
                                                            e,
                                                            categories
                                                      )
                                                }
                                                onDrop={(e) =>
                                                      handleDrop(e, index)
                                                }
                                          >
                                                <div>
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: item?.icon,
                                                            }}
                                                            className="category__icon"
                                                      ></div>
                                                </div>
                                                <div className="text-start">
                                                      <div>{item.title}</div>
                                                      <div className="text-muted">
                                                            {`${
                                                                  item.subtitle
                                                                        ? "Sub Title: " +
                                                                          item.subtitle
                                                                        : ""
                                                            }`}
                                                            {`${
                                                                  item.sub_category
                                                                        ? "Sub Category:" +
                                                                          item.sub_category
                                                                        : ""
                                                            } ${
                                                                  item?.parent_category
                                                                        ? "Parent Category:" +
                                                                          item?.parent_category
                                                                        : ""
                                                            }`}
                                                      </div>
                                                </div>
                                          </div>
                                    </>
                              ))
                        ) : (
                              <Spinner />
                        )}
                  </div>
            </>
      );
};

export default CategoryHomeManage;
