import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
      CardHeader,
      CardLinks,
      CardTitle,
} from "../../styles/components/common/Table";
import { LinkButton } from "../../styles/components/common/Button";
import FormInput from "../../components/form/FormInput";
import FormDescription from "../../components/form/FormDescription";
import FormStatusSelect from "../../components/form/FormStatusSelect";
import FormSelect from "../../components/form/FormSelect";
import FormImage from "../../components/form/FormImage";
import MultipleImage from "../../components/form/MultipleImage";
import FormButton from "../../components/form/FormButton";
import FormSeo from "../../components/common/FormSeo";
import ExtraDetail from "../../components/common/ExtraDetail";
import ExtraDownloads from "../../components/common/ExtraDownloads";
import ShopDetail from "../../components/common/ShopDetail";

const options = [
      {
            if: "Promo",
            title: "Promo",
      },
      {
            id: "Hot",
            title: "Hot",
      },
];

const ProductEdit = () => {
      const { productId } = useParams();
      const navigate = useNavigate();
      const [loadingData, setLoadingData] = useState(false);

      const [brands, setBrands] = useState([]);

      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [category, setCategory] = useState("");
      const [brand, setBrand] = useState("");
      const [title, setTitle] = useState("");
      const [slug, setSlug] = useState("");
      const [description, setDescription] = useState("");
      const [summary, setSummary] = useState("");
      const [installation, setInstallation] = useState("");
      const [specification, setSpecification] = useState("");
      const [warrenty, setWarrenty] = useState("");
      const [seoTitle, setSeoTitle] = useState("");
      const [seoKeyword, setSeoKeyword] = useState("");
      const [seoDescription, setSeoDescription] = useState("");
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [productImages, setProductImages] = useState([]);
      const [status, setStatus] = useState("");
      const [price, setPrice] = useState("");
      const [salePrice, setSalePrice] = useState("");
      const [type, setType] = useState("");
      const [stock, setStock] = useState("");

      const [downloads, setDownloads] = useState([]);
      const [downloadFiles, setDownloadFiles] = useState([]);
      const [productDownloads, setProductDownloads] = useState([]);

      const [item, setItem] = useState("");
      const [subCategory, setSubCategory] = useState("");
      const [childCategory, setChildCategory] = useState("");

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const [darazLink, setDarazLink] = useState("");
      const [meroPasalLink, setMeroPasalLink] = useState("");
      const [hardwarePasalLink, setHardwarePasalLink] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/extra/data`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTitle(response.data.product.title);
                              setSlug(response.data.product.slug);
                              setCategory(response.data.product.category_id);
                              setBrand(response.data.product.brand_id);
                              setProductImages(response.data.product.images);
                              setProductDownloads(
                                    response.data.product.downloads
                              );
                              setImageUrl(response.data.product.image);
                              setSeoTitle(response.data.product.seo_title);
                              setSeoKeyword(response.data.product.seo_keyword);
                              setDescription(response.data.product.description);
                              setInstallation(
                                    response.data.product.installation
                              );
                              setSpecification(
                                    response.data.product.specification
                              );
                              setWarrenty(response.data.product.warranty);
                              setSummary(response.data.product.summary);
                              setSeoDescription(
                                    response.data.product.seo_description
                              );
                              setStatus(response.data.product.status);

                              setStock(response.data.product.stock);
                              setPrice(response.data.product.price);
                              setSalePrice(response.data.product.sale_price);
                              setType(response.data.product.type);

                              setHardwarePasalLink(
                                    response.data.product.hardware_pasal_link
                              );
                              setDarazLink(response.data.product.daraz_link);
                              setMeroPasalLink(
                                    response.data.product.mero_pasal_link
                              );

                              setItem(response.data.category);
                              setChildCategories(response.data.childCategories);
                              setChildCategory(response.data.childCategory);
                              setSubCategories(response.data.subCategories);
                              setSubCategory(response.data.subCategory);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/products/${updatedSlug}/edit`);
                  }
            };

            const data = {
                  title: title,
                  slug: slug,
                  brand: brand,
                  category: category,
                  status: status,
                  description: description,
                  summary: summary,
                  installation: installation,
                  warrenty: warrenty,
                  specification: specification,
                  image: image,
                  images: imageFiles,
                  seo_title: seoTitle,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
                  downloads: downloadFiles,
                  stock: stock,
                  price: price,
                  sale_price: salePrice,
                  type: type,
                  mero_pasal_link: meroPasalLink,
                  daraz_link: darazLink,
                  hardware_pasal_link: hardwarePasalLink,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.brand) {
                                    toast.error(response.data.message.brand);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.stock) {
                                    toast.error(response.data.message.stock);
                              }
                              if (response.data.message.price) {
                                    toast.error(response.data.message.price);
                              }
                              if (response.data.message.sale_price) {
                                    toast.error(
                                          response.data.message.sale_price
                                    );
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                              if (response.data.message.hardware_pasal_link) {
                                    toast.error(
                                          response.data.message
                                                .hardware_pasal_link
                                    );
                              }
                              if (response.data.message.mero_pasal_link) {
                                    toast.error(
                                          response.data.message.mero_pasal_link
                                    );
                              }
                              if (response.data.message.daraz_link) {
                                    toast.error(
                                          response.data.message.daraz_link
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const loadCategory = async (value) => {
            setCategory(value);
            setItem(value);
            const data = {
                  id: value,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSubCategories(response.data.categories);
                              setChildCategories([]);
                        }
                  });
      };

      const loadSubCategory = async (value) => {
            setCategory(value);
            const data = {
                  id: value,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setChildCategories(response.data.categories);
                        }
                  });
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Edit Product
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/products">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                {!loadingData ? (
                                                      <Row className="g-3">
                                                            <FormInput
                                                                  title="Product Title"
                                                                  classes={7}
                                                                  value={title}
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setTitle
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Slug"
                                                                  classes={5}
                                                                  value={slug}
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setSlug
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Brand"
                                                                  classes={4}
                                                                  required={
                                                                        true
                                                                  }
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  selected={
                                                                        brand
                                                                  }
                                                                  options={
                                                                        brands
                                                                  }
                                                                  setData={
                                                                        setBrand
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Category"
                                                                  classes={4}
                                                                  selected={
                                                                        item
                                                                  }
                                                                  setData={
                                                                        loadCategory
                                                                  }
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        categories
                                                                  }
                                                            />
                                                            {subCategories.length >
                                                                  0 && (
                                                                  <FormSelect
                                                                        title="Sub Category"
                                                                        classes={
                                                                              4
                                                                        }
                                                                        selected={
                                                                              subCategory
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        required={
                                                                              true
                                                                        }
                                                                        setData={
                                                                              loadSubCategory
                                                                        }
                                                                        options={
                                                                              subCategories
                                                                        }
                                                                  />
                                                            )}
                                                            {childCategories.length >
                                                                  0 && (
                                                                  <FormSelect
                                                                        title="Child Category"
                                                                        classes={
                                                                              4
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        selected={
                                                                              childCategory
                                                                        }
                                                                        required={
                                                                              true
                                                                        }
                                                                        setData={
                                                                              setCategory
                                                                        }
                                                                        options={
                                                                              childCategories
                                                                        }
                                                                  />
                                                            )}
                                                            <FormInput
                                                                  title="Price"
                                                                  classes={4}
                                                                  value={price}
                                                                  handleChange={
                                                                        setPrice
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Sale Price"
                                                                  classes={4}
                                                                  value={
                                                                        salePrice
                                                                  }
                                                                  handleChange={
                                                                        setSalePrice
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Stock"
                                                                  classes={4}
                                                                  value={stock}
                                                                  handleChange={
                                                                        setStock
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  options={
                                                                        options
                                                                  }
                                                                  setData={
                                                                        setType
                                                                  }
                                                                  title="Type"
                                                                  classes={4}
                                                                  selected={
                                                                        type
                                                                  }
                                                            />
                                                            <FormStatusSelect
                                                                  title="Status"
                                                                  selected={
                                                                        status
                                                                  }
                                                                  classes={4}
                                                                  setStatus={
                                                                        setStatus
                                                                  }
                                                            />
                                                      </Row>
                                                ) : (
                                                      <div className="text-center py-5">
                                                            <Spinner />
                                                      </div>
                                                )}
                                          </Card.Body>
                                    </Card>
                                    {!loadingData ? (
                                          <>
                                                <Card className="mt-4">
                                                      <Card.Body>
                                                            <FormDescription
                                                                  title="Description"
                                                                  required={
                                                                        true
                                                                  }
                                                                  description={
                                                                        description
                                                                  }
                                                                  setDescription={
                                                                        setDescription
                                                                  }
                                                            />
                                                      </Card.Body>
                                                </Card>
                                                <Card className="mt-4">
                                                      <Card.Body>
                                                            <Row className="g-3">
                                                                  <FormImage
                                                                        title="Featured Image"
                                                                        setImage={
                                                                              setImage
                                                                        }
                                                                        setImageUrl={
                                                                              setImageUrl
                                                                        }
                                                                        required={
                                                                              true
                                                                        }
                                                                        imageUrl={
                                                                              imageUrl
                                                                        }
                                                                  />
                                                                  <MultipleImage
                                                                        title="Other Images (Upload multiple images)"
                                                                        images={
                                                                              images
                                                                        }
                                                                        websiteImages={
                                                                              productImages
                                                                        }
                                                                        setProductImages={
                                                                              setProductImages
                                                                        }
                                                                        type="images"
                                                                        grid={4}
                                                                        classes={
                                                                              8
                                                                        }
                                                                        setImages={
                                                                              setImages
                                                                        }
                                                                        setImageFiles={
                                                                              setImageFiles
                                                                        }
                                                                  />
                                                            </Row>
                                                      </Card.Body>
                                                </Card>
                                                <FormSeo
                                                      setSeoDescription={
                                                            setSeoDescription
                                                      }
                                                      setSeoTitle={setSeoTitle}
                                                      setSeoKeyword={
                                                            setSeoKeyword
                                                      }
                                                      description={
                                                            seoDescription
                                                      }
                                                      keyword={seoKeyword}
                                                      title={seoTitle}
                                                />
                                                <ExtraDetail
                                                      installation={
                                                            installation
                                                      }
                                                      warrenty={warrenty}
                                                      specification={
                                                            specification
                                                      }
                                                      setInstallation={
                                                            setInstallation
                                                      }
                                                      setSpecification={
                                                            setSpecification
                                                      }
                                                      setWarrenty={setWarrenty}
                                                />
                                                <ExtraDownloads
                                                      downlaods={downloads}
                                                      productDownloads={
                                                            productDownloads
                                                      }
                                                      setDownloadFiles={
                                                            setDownloadFiles
                                                      }
                                                      setDownlods={setDownloads}
                                                      type="download_files"
                                                      setProductDownlaods={
                                                            setProductDownloads
                                                      }
                                                />
                                                <ShopDetail
                                                      darazLink={darazLink}
                                                      setDarazLink={
                                                            setDarazLink
                                                      }
                                                      hardwarePasalLink={
                                                            hardwarePasalLink
                                                      }
                                                      setHardwarePasalLink={
                                                            setHardwarePasalLink
                                                      }
                                                      meroPasalLink={
                                                            meroPasalLink
                                                      }
                                                      setMeroPasalLink={
                                                            setMeroPasalLink
                                                      }
                                                />
                                                <Card className="mt-4 mb-5">
                                                      <Card.Body>
                                                            <Row>
                                                                  <Col lg={12}>
                                                                        <FormButton
                                                                              title="Submit"
                                                                              loading={
                                                                                    loading
                                                                              }
                                                                        />
                                                                  </Col>
                                                            </Row>
                                                      </Card.Body>
                                                </Card>
                                          </>
                                    ) : null}
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductEdit;
