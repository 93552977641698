import React, { useState, useEffect } from "react";

import axios from "axios";
import Progress from "react-progress-2";
import { Link, useParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import ProductListBox from "../../components/backend/ProductListBox";
import CardBody from "../../components/common/CardBody";
import CardHeader from "../../components/common/CardHeader";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import LinkButton from "../../components/common/LinkButton";
import { ItemShow } from "../../components/form";
import toast from "react-hot-toast";

const CategoryShow = () => {
      const { categoryId } = useParams();
      const [category, setCategory] = useState([]);

      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState("");

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`,
                  },
            })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const loadCategory = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategory(response.data.category);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategory();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Category Detail">
                                          <LinkButton
                                                link="/dashboard/categories"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      {category?.image && (
                                                            <Col lg={4}>
                                                                  <Card>
                                                                        <Card.Body className="text-center">
                                                                              <img
                                                                                    className="w-100"
                                                                                    src={`${category.image}`}
                                                                              />
                                                                        </Card.Body>
                                                                  </Card>
                                                            </Col>
                                                      )}
                                                      <Col lg="8">
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Name"
                                                                        value={
                                                                              category.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Subtitle"
                                                                        value={
                                                                              category.subtitle
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Website"
                                                                        value={
                                                                              category.link
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Pdf"
                                                                        value={
                                                                              <Link
                                                                                    to={
                                                                                          category.pdf
                                                                                    }
                                                                              />
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="By: "
                                                                        value={
                                                                              category.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date "
                                                                        value={
                                                                              category.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Title "
                                                                        value={
                                                                              category.seo_title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Keyword "
                                                                        value={
                                                                              category.seo_keyword
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="SEO Description "
                                                                        value={
                                                                              category.seo_description
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              category.status ? (
                                                                                    <>
                                                                                          <span className="text-success">
                                                                                                Active
                                                                                          </span>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <span className="text-warning">
                                                                                                Disable
                                                                                          </span>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Home Menu"
                                                                        value={
                                                                              category.navigation_status ? (
                                                                                    <>
                                                                                          <span className="text-success">
                                                                                                Active
                                                                                          </span>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <span className="text-warning">
                                                                                                Disable
                                                                                          </span>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ListGroup.Item>
                                                                        {" "}
                                                                        <strong>
                                                                              Description
                                                                              :{" "}
                                                                        </strong>{" "}
                                                                        <br />{" "}
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: category.description,
                                                                              }}
                                                                        ></div>
                                                                  </ListGroup.Item>
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center py-5">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                              {!loadingData ? (
                                    <Card className="mt-4">
                                          <CardHeader title="Product List" />
                                          <CardBody
                                                setLimit={setLimit}
                                                totalItems={totalProducts}
                                                currentPage={currentPage}
                                                perPages={perPages}
                                                setSearch={setSearch}
                                                getData={getData}
                                          >
                                                <ProductListBox
                                                      loading={loading}
                                                      products={products}
                                                      loadData={loadData}
                                                      setProducts={setProducts}
                                                      setLoading={setLoading}
                                                />
                                          </CardBody>
                                    </Card>
                              ) : null}
                        </Col>
                  </Row>
            </>
      );
};

export default CategoryShow;
