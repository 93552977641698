export const lightTheme = {
      body: "#F0F5F1",
      text: "#000000",
      bodyRgba: "252, 246, 244",
      textRgba: "0,0,0",
      white: "#ffffff",
      black: "#231f20",
      linkColor: "#333333",
      primaryColor: "#e86b19",
      secondaryColor: "#25252a",
      borderColor: "#e6e6e6",
      boxShadowColor: "rgba(35, 31, 32, 0.1)",
      cardBodyColor: "#f2f2f2",
};

export const DarkTheme = {
      body: "#000000",
      text: "#FCF6F4",
      fontFamily: "'Manrope', sans-serif",
      textRgba: "252, 246, 244",
      bodyRgba: "0,0,0",
};
