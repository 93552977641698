import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { Card, Col, ListGroupItem, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import { ItemShow } from "../../components/form";
import toast from "react-hot-toast";

const ProductEnquiryShow = () => {
      const { enquiryId } = useParams();
      const [enquiry, setEnquiry] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/product_enquiries/${enquiryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setEnquiry(response.data.enquiry);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Enquiry Details">
                                          <LinkButton
                                                link="/dashboard/product_enquiries"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Full Name "
                                                                  value={
                                                                        enquiry.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email "
                                                                  value={
                                                                        enquiry.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone "
                                                                  value={
                                                                        enquiry.phone_number
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Remarks"
                                                                  value={
                                                                        enquiry.remarks
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        enquiry.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ListGroupItem>
                                                                  <img
                                                                        src={
                                                                              enquiry
                                                                                    ?.product
                                                                                    ?.image
                                                                        }
                                                                        className="w-100"
                                                                        alt=""
                                                                  />
                                                            </ListGroupItem>
                                                            <ItemShow
                                                                  title="Product Name "
                                                                  value={
                                                                        enquiry
                                                                              .product
                                                                              ?.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category "
                                                                  value={
                                                                        enquiry
                                                                              .product
                                                                              ?.category_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Brand "
                                                                  value={
                                                                        enquiry
                                                                              .product
                                                                              ?.brand_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Price"
                                                                  value={
                                                                        enquiry
                                                                              .product
                                                                              ?.price
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductEnquiryShow;
