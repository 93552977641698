import React from "react";
import { Accordion, Row } from "react-bootstrap";
import { FormInput } from "../../form";

const ShopDetail = ({
      darazLink,
      hardwarePasalLink,
      meroPasalLink,
      setDarazLink,
      setHardwarePasalLink,
      setMeroPasalLink,
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Shop Details</Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <FormInput
                                                value={darazLink}
                                                handleChange={setDarazLink}
                                                title="Daraz Link"
                                          />
                                          <FormInput
                                                value={hardwarePasalLink}
                                                handleChange={
                                                      setHardwarePasalLink
                                                }
                                                title="Hardware Pasal Link"
                                          />
                                          <FormInput
                                                value={meroPasalLink}
                                                handleChange={setMeroPasalLink}
                                                title="Mero Pasal Link"
                                          />
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default ShopDetail;
