import React from "react";

const EditIcon = () => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20.547 20.299"
                        fill="currentColor"
                        width="16"
                  >
                        <g stroke="currentColor" stroke-width=".4">
                              <path
                                    data-name="Path 78"
                                    d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                              ></path>
                              <path
                                    data-name="Path 79"
                                    d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                              ></path>
                        </g>
                  </svg>
            </>
      );
};

export default EditIcon;
